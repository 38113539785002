<template>
  <b-card no-body>
    <div class="m-2">
      <!-- Table Top -->
      <b-row>
        <!-- Per Page -->
        <b-col
          cols="12"
          md="6"
          class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
        >
          <label>{{ $t("eachPage") }}</label>
          <v-select
            v-model="perPage"
            transition=""
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="perPageOptions"
            :clearable="false"
            class="per-page-selector d-inline-block mx-50"
          />
          <label> {{ $t("piece") }} </label>
        </b-col>

        <!-- Search -->
        <b-col cols="12" md="6">
          <div class="d-flex align-items-center justify-content-end">
            <b-form-input
              class="d-inline-block mr-1"
              :placeholder="$t('search')"
              :data="searchQuery"
              @input="priceListUpdate"
            />
            <b-button variant="success" @click="updatePrices()">
              {{ $t("update") }}
            </b-button>
          </div>
        </b-col>
      </b-row>
    </div>
    <div>
      <b-form
        ref="form"
        :style="{ height: trHeight }"
        class="repeater-form"
        @submit.prevent="repeateAgain"
      >
        <b-table
          v-if="true"
          ref="refPricesTable"
          :items="prices"
          striped
          :small="true"
          :fields="fields"
          responsive
          class="editable-table"
          primary-key="ikey"
          bordered
          show-empty
          :empty-text="$t('noRecords')"
          :per-page="perPage"
          :current-page="currentPage"
        >
          <template #cell(price)="{ item }">
            <div class="editable text-right max-w-150">
              <b-form-input
                :id="item.vade0name"
                v-model="item.price"
                style="text-align: right"
                type="number"
                :options="{
                  numeral: true,
                }"
                @input="changedItem(item)"
              />
            </div>
          </template>
        </b-table>
        <div class="mx-2 mb-2">
          <b-row>
            <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-start"
            >
              <span class="text-muted"
                >{{ $t("showing") }} {{ dataMeta.from }} {{ $t("to") }}
                {{ dataMeta.to }} {{ $t("of") }} {{ dataMeta.of }}
                {{ $t("entries") }} </span
              >
            </b-col>
            <!-- Pagination -->
            <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-end"
            >
              <b-pagination
                v-model="currentPage"
                :total-rows="prices.length"
                :per-page="perPage"
                first-number
                last-number
                class="mb-0 mt-1 mt-sm-0"
                prev-class="prev-item"
                next-class="next-item"
              >
                <template #prev-text>
                  <feather-icon icon="ChevronLeftIcon" size="18" />
                </template>
                <template #next-text>
                  <feather-icon icon="ChevronRightIcon" size="18" />
                </template>
              </b-pagination>
            </b-col>
          </b-row>
        </div>
      </b-form>
    </div>
  </b-card>
</template>

<script>
import {
  BForm,
  BFormInput,
  BRow,
  BCol,
  BButton,
  BCard,
  BTable,
  BPagination,
} from "bootstrap-vue";
import { heightTransition } from "@core/mixins/ui/transition";
import Ripple from "vue-ripple-directive";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import VueI18n from "@/libs/i18n";
import vSelect from "vue-select";
export default {
  components: {
    BForm,
    BRow,
    BCol,
    BButton,
    BFormInput,
    BCard,
    BTable,
    BPagination,
    vSelect,
  },
  directives: {
    Ripple,
  },
  mixins: [heightTransition],
  beforeRouteEnter(to, from, next) {
    if (to.name == "ingredientsPrices") {
      to.meta.breadcrumb = [];

      to.meta.breadcrumb.push({
        text: VueI18n.t("ingredientPrices"),
        active: true,
      });
    }
    next();
  },
  data() {
    return {
      prices: [],
      allPrices: [],
      changedItems: [],
      searchQuery: "",
      perPageOptions: [5, 10, 25, 50],
      perPage: 10,
      currentPage: 1,
      fields: [],
    };
  },
  computed: {
    dataMeta() {
      const localItemsCount = this.prices.length;
      const to = this.perPage * this.currentPage < localItemsCount;
      return {
        from: this.perPage * (this.currentPage - 1) + (localItemsCount ? 1 : 0),
        to: to
          ? this.perPage * (this.currentPage - 1) + this.perPage
          : localItemsCount,
        of: this.allPrices.length,
      };
    },
  },
  async mounted() {
    this.prices = await this.fetchPrices();

    this.allPrices = this.prices;
    this.fields = [
      {
        key: "kod",
        label: this.$t("code"),
        sortable: true,
        class: "text-right",
      },
      {
        key: "ingredientname",
        label: this.$t("ingredientName"),
        sortable: true,
      },
      {
        key: "price",
        label: this.$t("price"),
        tdClass: "max-w-150",
      },
    ];
  },

  methods: {
    async fetchPrices() {
      return (
        await this.$http.get("/Ingredients/GetIngredientPrices/", {
          headers: {
            plantid: this.$store.state.app.selectedPlantId,
          },
        })
      ).data.resultdata;
    },
    priceListUpdate(val) {
      if (val == undefined) val = this.searchQuery;
      this.searchQuery = val;

      if (
        this.searchQuery !== "" &&
        this.searchQuery != null &&
        this.searchQuery != undefined
      )
        this.prices = this.allPrices.filter((x) =>
          x.ingredientname
            .toLowerCase()
            .includes(this.searchQuery.toLowerCase())
        );
      else this.prices = this.allPrices;
    },
    changedItem(item) {
      var index = this.changedItems.map((x) => x.id).indexOf(item.id);
      if (index == -1) this.changedItems.push(item);
      else this.changedItems.splice(index, 1, item);
    },
    async updatePrices() {
      var ikili = this.changedItems.map((x) => {
        return {
          id: x.id,
          deger: parseFloat(x.price),
        };
      });
      var result = await this.$http.put(
        `/Ingredients/UpdateIngredientPrices/${this.$store.state.app.selectedPlantId}`,
        JSON.stringify(ikili)
      );
      if (result.data.status == "OK")
        this.$toast({
          component: ToastificationContent,
          position: "top-right",
          props: {
            title: this.$t("successful"),
            icon: "CheckIcon",
            variant: "success",
            text: this.$t("updated", { type: this.$t("prices") }),
          },
        });
      else
        this.$toast({
          component: ToastificationContent,
          position: "top-right",
          props: {
            title: this.$t("unsuccessful"),
            icon: "AlertTriangleIcon",
            variant: "danger",
            text: this.$t("notUpdated", { type: this.$t("prices") }),
          },
        });
    },
  },
};
</script>

<style>
.max-w-150 {
  width: 160px;
}
</style>
